import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

const Index = () => (
  <Layout>
    <h1>Fonts Api for SST</h1>
  </Layout>
)

export const Head = () => <Seo title="SST-Fonts" />

export default Index
